import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const TicketSalesAggregationChart = ({ timeslotData }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (timeslotData && timeslotData.length > 0) {
      const processed = processAggregation(timeslotData);
      setChartData(processed);
    }
  }, [timeslotData]);

  // Aggregation function:
  // 1. For each record, compute the normalized day by comparing event_date with the normalized Friday (from event_id).
  // 2. Aggregate per event (to avoid double-counting) then group across events by (normalizedDay, time_slot).
  // 3. Compute averages for each group.
  const processAggregation = (data) => {
    const eventAggregates = {}; // key: `${normalizedDay}_${time_slot}_${event_id}` → sum of ticket counts
    data.forEach(record => {
      const { event_id, event_date, time_slot, daily_ticket_count } = record;
      if (!event_id || !event_date || !time_slot) return;
      
      // Extract normalized Friday from event_id.
      // Example event_id: "Fort Wayne_IN_2024-11-22"
      const parts = event_id.split('_');
      const normFridayStr = parts[parts.length - 1]; // "2024-11-22"
      const normFriday = new Date(normFridayStr);
      const eventDate = new Date(event_date);
      
      // Calculate the difference in days between event_date and normalized Friday.
      const diffDays = Math.round((eventDate - normFriday) / (1000 * 3600 * 24));
      const dayMap = { 0: 'Friday', 1: 'Saturday', 2: 'Sunday', 3: 'Monday' };
      const normalizedDay = dayMap[diffDays];
      if (!normalizedDay) return;
      
      const key = `${normalizedDay}_${time_slot}_${event_id}`;
      eventAggregates[key] = (eventAggregates[key] || 0) + Number(daily_ticket_count);
    });

    // Group across events by (normalizedDay, time_slot)
    const groupSums = {}; // key: `${normalizedDay}_${time_slot}` → { sum, count }
    Object.keys(eventAggregates).forEach(key => {
      // key is formatted like "Friday_09:00_Fort Wayne_IN_2024-11-22"
      const [normalizedDay, timeSlot] = key.split('_');
      const groupKey = `${normalizedDay}_${timeSlot}`;
      if (!groupSums[groupKey]) {
        groupSums[groupKey] = { sum: 0, count: 0 };
      }
      groupSums[groupKey].sum += eventAggregates[key];
      groupSums[groupKey].count += 1;
    });

    // Compute averages for each (normalizedDay, time_slot) pair.
    const averages = {};
    Object.keys(groupSums).forEach(groupKey => {
      const { sum, count } = groupSums[groupKey];
      averages[groupKey] = sum / count;
    });

    // Prepare chart data for ApexCharts.
    const normalizedDays = ['Friday', 'Saturday', 'Sunday', 'Monday'];
    
    // Get the unique timeslots.
    const timeslotSet = new Set();
    Object.keys(groupSums).forEach(key => {
      const parts = key.split('_');
      timeslotSet.add(parts[1]);
    });
    const timeslots = Array.from(timeslotSet).sort();
    
    // Build series: one series per timeslot.
    const series = timeslots.map(timeSlot => {
      const data = normalizedDays.map(day => {
        const groupKey = `${day}_${timeSlot}`;
        return averages[groupKey] || 0;
      });
      return { name: timeSlot, data };
    });
    
    const options = {
      chart: {
        type: 'bar',
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      title: {
        text: 'Average Ticket Count by Event Day & Timeslot',
      },
      yaxis: {
        title: {
          text: 'Event Day',
        },
      },
      xaxis: {
        categories: normalizedDays,
        title: {
          text: 'Average Ticket Count',
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val); // or val.toFixed(0)
        },
        style: {
          colors: ['#fff'], // keeps the labels white on each bar
        },
      },
      tooltip: {
        y: {
          formatter: (val) => Math.round(val),
        },
      },
      legend: {
        position: 'top',
      },
    };
        

    return { options, series };
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {chartData ? (
        <Chart options={chartData.options} series={chartData.series} type="bar" height={400} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export default TicketSalesAggregationChart;
