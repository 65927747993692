import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import { fetchTimeslotData } from '../services/timeslot';
import TicketSalesAggregationChart from './UI-components/Timeslot/TicketSalesAggregationChart';


const Timeslot = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [timeslotData, setTimeslotData] = useState([]);
  const state = useSelector((state) => state.ticketure);
  const companyName = useSelector((state) => state.company.company);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const timeslotDataResponse = await Promise.all([
          dispatch(fetchTimeslotData())
        ]);

        const timeslotPayload = timeslotDataResponse[0].payload || [];
        setTimeslotData(timeslotPayload);
        console.log(timeslotData);

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [dispatch, companyName])

  return (
    <Paper style={{ padding: '10px' }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div style={{ height: '70vh', width: 'auto', overflowX: 'false', position: 'relative', margin: 'auto' }}>
            <DataGrid 
              rows={timeslotData}
              columns={[
                { field: 'sale_date', headerName: 'Sale Date', width: 150 },
                { field: 'event_id', headerName: 'Event ID', width: 250 },
                { field: 'event_date', headerName: 'Event Date', width: 150 },
                { field: 'time_slot', headerName: 'Time Slot', width: 150 },
                { field: 'daily_ticket_count', headerName: 'Ticket Count', width: 150 },
                { field: 'daily_orders', headerName: 'Order Count', width: 150 },
              ]}
              slots={{ toolbar: GridToolbar }}
              style={{ width: '100%', position: 'absolute' }}
              showCellVerticalBorder
              showColumnVerticalBorder
            />
          </div>
          <TicketSalesAggregationChart timeslotData={timeslotData} />
        </>

      )}
    </Paper>
  )
}

export default Timeslot;