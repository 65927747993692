import { combineReducers, configureStore } from "@reduxjs/toolkit";
import companySlice from "./companySlice";
import storage from 'redux-persist/lib/storage';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { ticketureReducer } from "./ticketure";
import competitionSlice from "./competitionSlice";
import drilldownSlice from "./drilldownSlice";
import { timeslotReducer } from "./timeslot";

const allreducers = combineReducers({
  company: companySlice,
  ticketure: ticketureReducer,
  competitions: competitionSlice,
  drilldown: drilldownSlice,
  timeslot: timeslotReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist:['company']
};

const persistedReducer = persistReducer(persistConfig , allreducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      serializableCheck: false
    }),
});

export const persistor = persistStore(store);