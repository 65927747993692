import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import SkeletonLoader from './SkeletonLoader';
import Chart from 'react-apexcharts';
import { FormControlLabel, Switch } from '@mui/material';
import { Box, Card } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import {
  fetchTicketureByMarket,
  fetchTicketureZipCodeByEventID,
} from '../services/ticketure';
import { fetchTimeslotData } from '../services/timeslot';
import MarketDataBox from './MarketDataBox';
import MarketMap from './MarketMap';
import TicketSalesAggregationChart from './UI-components/Timeslot/TicketSalesAggregationChart';

const MarketPage = () => {
  const { marketId } = useParams();
  const [showCumulative, setShowCumulative] = useState(false);
  const [marketSegmentationData, setMarketSegmentationData] = useState([]);
  const [mapCoords, setMapCoords] = useState([29.7589382, -95.3676974]);
  const [eventIds, setEventIds] = useState([]);
  const [zipData, setZipData] = useState(null);
  const [eventSalesData, setEventSalesData] = useState([]);
  const [isLoadingSalesData, setIsLoadingSalesData] = useState(false);

  const dispatch = useDispatch();
  const ticketureState = useSelector((state) => state.ticketure);
  const marketData = ticketureState.marketData;
  const loading = ticketureState.loading;
  const error = ticketureState.error;
  const companyName = useSelector((state) => state.company.company);
  const [timeslotData, setTimeslotData] = useState([]);
  const [filteredTimeslotData, setFilteredTimeslotData] = useState([]);

  const handleToggleChange = () => {
    setShowCumulative(!showCumulative);
  };

  const formatMarketId = (marketId) => {
    return marketId
      .split(',')
      .map((part, index) => {
        return index === 0 ? part.replace(/\b\w/g, (char) => char.toUpperCase()) : part.toUpperCase();
      })
      .join(',');
  };

  const decodedMarketId = decodeURIComponent(marketId).toLowerCase();
  const formattedMarketId = formatMarketId(decodedMarketId);

  // Fetch market data when component mounts or marketId changes
  useEffect(() => {
    dispatch(fetchTicketureByMarket(formattedMarketId));
  }, [dispatch, formattedMarketId, companyName]);

  // Set event IDs when market data changes
  useEffect(() => {
    if (marketData) {
      const uniqueEventIds = [...new Set(marketData.map((item) => item.event_id))];
      setEventIds(uniqueEventIds);
    }
  }, [marketData, companyName]);

  // Fetch zip data
  useEffect(() => {
    fetch('/zip.json')
      .then((response) => response.json())
      .then((data) => setZipData(data))
      .catch((error) => console.error('Error loading zip data:', error));
  }, []);

  // Fetch sales data for event IDs
  useEffect(() => {
    if (eventIds.length > 0) {
      setIsLoadingSalesData(true);
      const fetchAllSalesData = async () => {
        const allSalesData = await Promise.all(
          eventIds.map((eventID) => dispatch(fetchTicketureZipCodeByEventID(eventID)))
        );

        // Extract payloads and add event_id to each sale
        const rawData = allSalesData.flatMap((response, index) =>
          response.payload.map((sale) => ({
            ...sale,
            event_id: eventIds[index],
          }))
        );

        // Group sales data by event_id
        const groupedData = eventIds.map((eventID) => ({
          event_id: eventID,
          sales: rawData.filter((sale) => sale.event_id === eventID),
        }));

        setEventSalesData(groupedData);
        setIsLoadingSalesData(false);
      };
      fetchAllSalesData();
    } else {
      setEventSalesData([]);
      setIsLoadingSalesData(false);
    }
  }, [dispatch, eventIds, companyName]);

  useEffect(() => {
    const fetchTSData = async () => {
      try {
        const timeslotDataResponse = await Promise.all([
          dispatch(fetchTimeslotData())
        ]);
        const timeslotPayload = timeslotDataResponse[0].payload || [];
        setTimeslotData(timeslotPayload);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchTSData();
  }, [dispatch, companyName]);

  useEffect(() => {
    if (eventIds && eventIds.length && timeslotData && timeslotData.length) {
      const filtered = timeslotData.filter(item => eventIds.includes(item.event_id));
      setFilteredTimeslotData(filtered);
    }
  }, [eventIds, timeslotData]);
  

  const chartData = useMemo(() => {
    if (!marketData) return { series: [] };

    const groupedData = marketData.reduce((acc, item) => {
      if (!acc[item.event_id]) {
        acc[item.event_id] = new Map();
      }
      const yValue = showCumulative ? item.cumulative_tickets : item.daily_ticket_count;
      acc[item.event_id].set(item.days_out, {
        y: yValue,
        sale_date: item.sale_date,
      });
      return acc;
    }, {});

    const series = Object.keys(groupedData).map((event_id) => {
      const sortedData = Array.from(groupedData[event_id].entries())
        .sort((a, b) => a[0] - b[0])
        .filter(([x]) => x >= -3)
        .map(([x, { y, sale_date }]) => ({
          x,
          y,
          sale_date,
        }));

      return {
        name: event_id,
        data: sortedData,
      };
    });

    return { series };
  }, [marketData, showCumulative]);

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: { show: true },
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
      },
      animations: {
        enabled: true,
        speed: 400,
        animateGradually: {
          enabled: false,
          speed: 1000,
        },
        dynamicAnimations: {
          enabled: true,
          speed: 1000,
        },
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
      lineCap: 'round',
    },
    grid: {
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      title: {
        text: 'Days Out',
      },
      type: 'numeric',
      labels: {
        formatter: (value) => Math.floor(value),
      },
      min: -2,
      max: 60,
    },
    yaxis: {
      title: {
        text: showCumulative ? 'Cumulative Ticket Count' : 'Daily Ticket Count',
      },
      min: 0,
    },
    title: {
      text: 'Ticket Sales Over Time',
      align: 'left',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    tooltip: {
      enabled: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
        const daysOut = dataPoint.x;
        const ticketCount = dataPoint.y;
        const saleDate = dataPoint.sale_date;
  
        return `
          <div style="padding: 10px; border: 1px solid #ccc; background: white;">
            <strong>Event: ${w.config.series[seriesIndex].name}</strong><br />
            Days Out: ${daysOut}<br />
            Sale Date: ${saleDate}<br />
            Ticket Count: ${ticketCount}
          </div>
        `;
      },
    },
  };

  const { data: staticData } = useQuery('forecastData', async () => {
    const response = await axios.get('https://fqe-analytics-server-e1c19d4ce6cf.herokuapp.com/api/forecast-data');
    return response.data;
  });

  useEffect(() => {
    if (staticData) {
      const filteredStaticData = staticData.filter((event) => {
        const marketName = event['Cluster Market'].toLowerCase();
        return marketName === marketId;
      });
      if (filteredStaticData.length > 0) {
        const newCoords = [filteredStaticData[0].latitude, filteredStaticData[0].longitude];
        setMapCoords(newCoords);
        setMarketSegmentationData(filteredStaticData);
      }
    }
  }, [staticData, marketData, marketId]);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div>
        <h1 className='market-header'>{formattedMarketId}</h1>
        {loading ? (
          <SkeletonLoader loading={loading} width='100%' height={440} />
        ) : (
          <Card>
            <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <div style={{ padding: '10px' }}>
                  <FormControlLabel
                    control={<Switch checked={showCumulative} onChange={handleToggleChange} />}
                    label='Show Cumulative Sales'
                  />
                </div>
              </div>
              <Chart options={chartOptions} series={chartData.series} type='line' height={350} style={{ marginTop: 20 }} />
            </Box>
          </Card>
        )}
        <MarketDataBox data={marketSegmentationData} />
        <TicketSalesAggregationChart timeslotData={filteredTimeslotData} />
        {isLoadingSalesData || !zipData ? (
          <SkeletonLoader loading={isLoadingSalesData || !zipData} width='100%' height={400} />
        ) : (
          <div className='map-container' id='market-map'>
            <div className='map'>
              <MarketMap center={mapCoords} eventSalesData={eventSalesData} zipData={zipData} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketPage;