import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services';

const fetchData = async (url, company) => {
  try {
    const response = await axiosInstance.get(`${company}${url}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Thunks
export const fetchTimeslotData = createAsyncThunk(
  'timeslot/fetchTimeslotData',
  async (_, { getState, dispatch }) => {
    const url = `/timeslot_ticket_sales/`;
    return fetchData(url, getState().company.company);
  }
);